import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import SEO from "../components/seo";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    flex: 1
  },
  flexWrapCenter: {
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  paddingAll: {
    padding: "70px 15px"
  },
  textBlockContent: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: 960,
    width: "100%"
  },
  textWhiteCentered: {
    color: "#fff",
    textAlign: "center"
  }
}));

function Impressum() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SEO title={"Impressum"} />
      <div className={classNames(classes.paddingAll)}>
        <div className={classes.textBlockContent}>
          <Typography className={classes.textWhiteCentered} variant="headline">
            Impressum
          </Typography>
          <Typography className={classes.textWhiteCentered} variant="body2">
            Neu, Li, Busch, Becker GbR
          </Typography>
          <Typography className={classes.textWhiteCentered} variant="body1">
            Günther-Wagner-Allee 29
          </Typography>
          <Typography className={classes.textWhiteCentered} variant="body1">
            30177 Hannover
          </Typography>
          <a href="mailto:info@vollkorn.games">
            <Typography className={classes.textWhiteCentered} variant="body1">
              info@vollkorn.games
            </Typography>
          </a>
          <Typography className={classes.textWhiteCentered} variant="body1">
            USt-IdNr.: DE307067607
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
